import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BiModule } from '@selfai-platform/bi';

import { DATA_SOURCE_TYPE_LIST } from '@selfai-platform/bi-domain';
import { PageIsReadyComponent } from '@selfai-platform/bi-shared';
import { DatasourceTypes } from '@selfai-platform/pipeline-common';
import { PipelineModuleModule, PipelineProviderModule } from '@selfai-platform/pipeline-module';
import {
  CONFIG_PATH,
  ConfigService,
  DestroyService,
  PACKAGE_VERSION,
  SELFAI_APP_BASE_HREF,
  ScriptService,
  SelfaiAppModules,
  SharedModule,
} from '@selfai-platform/shared';
import { HomePageWidgets, ShellModule, UiLanguages, notFoundRoute } from '@selfai-platform/shell';
import { ToastModule } from 'primeng/toast';
import packageJson from '../../package.json';
import { AppComponent } from './app.component';
import { SelfaiStoreModule } from '@selfai-platform/store';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    DragDropModule,
    CommonModule,
    HttpClientModule,
    SharedModule,
    ToastModule,
    BiModule,
    SelfaiStoreModule,
    PageIsReadyComponent,
    ShellModule.forRoot({
      languages: [UiLanguages.RU, UiLanguages.EN],
      homePageWidgets: [HomePageWidgets.SelfaiBi, HomePageWidgets.Pipeline],
      modules: [SelfaiAppModules.KD, SelfaiAppModules.KE],
    }),
    PipelineModuleModule,
    PipelineProviderModule.forRoot({
      avaliableDatasources: [DatasourceTypes.externalFile, DatasourceTypes.jdbc],
    }),
    RouterModule.forRoot([notFoundRoute], {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: SELFAI_APP_BASE_HREF,
      useFactory: setAppBasehref,
      deps: [LocationStrategy],
    },
    ConfigService,
    {
      provide: PACKAGE_VERSION,
      useValue: packageJson.version,
    },
    {
      provide: DATA_SOURCE_TYPE_LIST,
      useValue: ['file', 'database', 'druid', 's3-storage'],
    },
    {
      provide: CONFIG_PATH,
      useValue: '/config.json',
    },
    ScriptService,
    DestroyService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function setAppBasehref(locStrategy: LocationStrategy) {
  return locStrategy.getBaseHref();
}
