import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import * as _ from 'lodash';
import moment from 'moment';
import { ClipboardService } from 'ngx-clipboard';
import { combineLatest, filter, takeUntil } from 'rxjs';

import {
  BarChartComponent,
  BaseChart,
  DashboardFiltersService,
  FilterSelectionService,
  GridChartComponent,
  LineChartComponent,
  OptionGenerator,
  isLegacyChartType,
} from '@selfai-platform/bi-chart-engine';
import {
  BoardConfiguration,
  BoardDataSource,
  BoardSyncOptions,
  BoardWidgetOptions,
  BrushType,
  ChartMouseMode,
  ChartSelectInfo,
  ChartSelectMode,
  ChartType,
  ConnectionType,
  CustomField,
  Dashboard,
  DashboardPageRelation,
  Datasource,
  DatasourceField as Field,
  Filter,
  FunctionValidator,
  GlobalActiveFiltersService,
  LayoutMode,
  LegendConvertType,
  PageWidget,
  PageWidgetConfiguration,
  Pivot,
  SPEC_VERSION,
  SearchQueryRequest,
  Shelf,
  ShelfLayers,
  UIOption,
  WIDGET_CONTEXT_ID,
  Widget,
  createPageWidget,
  createPageWidgetConfiguration,
  createShelf,
  createShelfLayers,
  getDownloadFilters,
} from '@selfai-platform/bi-domain';
import { DestroyService, isNullOrUndefined } from '@selfai-platform/shared';
import { KNOWLEDGE_DESIGNER_ROOT_ROUTE } from '@selfai-platform/shell';

import { DataDownloadComponent } from '../../../common/component/data-download/data.download.component';
import { GridComponent } from '../../../common/component/grid/grid.component';
import { SlickGridHeader, header } from '../../../common/component/grid/grid.header';
import { GridOption } from '../../../common/component/grid/grid.option';
import { EventBroadcaster } from '../../../common/event/event.broadcaster';
import { ImageService } from '../../../common/service/image.service';
import { DatasourceService } from '../../../datasource/service/datasource.service';
import { getChartIconClass } from '../../../page';
import { AnalysisPredictionService } from '../../../page/component/analysis/service/analysis.prediction.service';
import { ChartLimitInfo } from '../../dto';
import { WidgetService } from '../../service/widget.service';
import { convertPageWidgetSpecToServer } from '../../util';
import { DashboardUtil } from '../../util/dashboard.util';
import { AbstractWidgetComponent } from '../abstract-widget.component';

import { findChildWidgetIds } from './page-widget/find-child-widget-ids';
import { findParentWidgetId } from './page-widget/find-parent-widget-id';
import { makeSearchQueryParam } from './page-widget/make-search-query-param';
import { useCustomField } from './page-widget/use-custom-field';
import { PageWidgetSelectionFilterService } from './services/selection-filter.service';
import { WidgetFilterService } from './services/widget-filter.service';

declare let $;

@Component({
  selector: 'page-widget',
  templateUrl: 'page-widget.component.html',
  styleUrls: ['page-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService, PageWidgetSelectionFilterService, WidgetFilterService],
})
export class PageWidgetComponent extends AbstractWidgetComponent implements OnInit, OnDestroy {
  kdRootRoute = KNOWLEDGE_DESIGNER_ROOT_ROUTE;

  @Input()
  public widgetOption: BoardWidgetOptions;

  @Input('widget')
  public inputWidget: PageWidget;

  @Input()
  public readonly = false;

  @Input()
  dashboard: Dashboard;

  @ViewChild('chart')
  private chart: BaseChart;

  @ViewChild('gridChart')
  private gridChart: GridChartComponent;

  @ViewChild('dataGrid')
  private _dataGridComp: GridComponent;

  @ViewChild(DataDownloadComponent)
  private _dataDownComp: DataDownloadComponent;

  @ViewChild('userFuncInput')
  private _userFuncInput: ElementRef;

  private _isDuringProcess = false;
  private _interval: any;
  private _timer: any;

  private _selectFilterList: any[] = [];

  private _currentGlobalFilterString = '';

  private _childWidgetIds: string[] = [];

  protected resultData: any;

  protected gridUiOption: UIOption;

  public userCustomFunction: string;

  public widget: PageWidget;
  public parentWidget: Widget;

  public widgetConfiguration: PageWidgetConfiguration = createPageWidgetConfiguration();

  public chartType: string;

  public isMaximize = false;
  public mouseMode = 'SINGLE';

  public isSetChartData = false;
  public isUpdateRedraw = true;
  public isShowHierarchyView = false;
  public isInvalidPivot = false;
  public isShowNoData = false;
  public isShowDownloadPopup = false;
  public duringDataDown = false;
  public duringImageDown = false;
  public isShowEvtTriggerEditor = false;

  public limitInfo: ChartLimitInfo = {
    id: '',
    isShow: false,
    currentCnt: 0,
    maxCnt: 0,
  };

  public useCustomField = false;

  public chartFuncValidator: FunctionValidator = new FunctionValidator();

  public query: SearchQueryRequest;

  public isOriginDown = false;
  public srchText = '';
  public isCanNotDownAggr = false;

  public isRealTimeDs = false;
  public isRealTimeWidget = false;

  public $window = window;

  get uiOption(): UIOption {
    return this.widgetConfiguration.chart;
  }

  set uiOption(uiOption: UIOption) {
    this.widgetConfiguration.chart = uiOption;
  }

  get isShowChartTools() {
    return !this.isShowHierarchyView && !this.isError && !this.isShowNoData;
  }

  get isNotMapType() {
    return this.chart.uiOption.type !== ChartType.MAP;
  }

  get widgetChartIconClass() {
    if (this?.widget?.configuration?.chart?.type) {
      return getChartIconClass(this.widget.configuration.chart.type);
    }
    return '';
  }

  constructor(
    private datasourceService: DatasourceService,
    private widgetService: WidgetService,
    private imageService: ImageService,
    private analysisPredictionService: AnalysisPredictionService,
    private _clipboardService: ClipboardService,

    protected broadCaster: EventBroadcaster,
    protected elementRef: ElementRef,
    protected injector: Injector,
    private readonly selectionFilterService: PageWidgetSelectionFilterService,
    private readonly dashboardFiltersService: DashboardFiltersService,
    private readonly widgetFilterService: WidgetFilterService,
    private readonly filterSelectionService: FilterSelectionService,
    private readonly destroy$: DestroyService,
    @Inject(WIDGET_CONTEXT_ID) private contextId: string,
  ) {
    super(broadCaster, elementRef, injector);
  }

  public ngOnInit() {
    this.widget = createPageWidget({
      ...this.inputWidget,
    });
    this._checkDatasource();
    super.ngOnInit();
  }

  public ngOnChanges(changes: SimpleChanges) {
    const inputWidgetChanges: SimpleChange = changes.inputWidget;
    if (inputWidgetChanges && inputWidgetChanges.currentValue) {
      this._setWidget(inputWidgetChanges.currentValue);
    }
  }

  public ngAfterViewInit() {
    super.ngAfterViewInit();
    this.useCustomField = useCustomField(this.widget.configuration);

    this.subscriptions.push(
      this.broadCaster.on<any>('CHANGE_THEME').subscribe((data) => {
        this.chart.draw(true);
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<any>('REFRESH_WIDGET').subscribe((data) => {
        this.widget.id === data.widgetId && this._search();
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<any>('WIDGET_CHANGE_TITLE').subscribe((data) => {
        if (this.isShowHierarchyView && this.parentWidget) {
          if (this.parentWidget.id === data.widgetId) {
            this.parentWidget.name = data.value;
          } else if (this.widget.id === data.widgetId) {
            this.widget.name = data.value;
          }
          this.safelyDetectChanges();
        }
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<any>('TOGGLE_LEGEND').subscribe((data) => {
        if (data.widgetId === this.widget.id) {
          this.toggleLegend();
        }
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<any>('TOGGLE_MINIMAP').subscribe((data) => {
        if (data.widgetId === this.widget.id) {
          this.toggleMiniMap();
        }
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<any>('CHANGE_MODE').subscribe((data) => {
        if (data.widgetId === this.widget.id) {
          this.changeMode(data.mode);
        }
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<any>('SET_SELECTION_FILTER').subscribe((data) => {
        if ((data.widgetId && data.widgetId === this.widget.id) || data.excludeWidgetId !== this.widget.id) {
          if (
            this.userCustomFunction &&
            '' !== this.userCustomFunction &&
            -1 < this.userCustomFunction.indexOf('main')
          ) {
            const strScript = '(' + this.userCustomFunction + ')';

            try {
              if (
                // it's legacy code
                // eslint-disable-next-line no-eval
                eval(strScript)({
                  name: 'SelectionFilterEvent',
                  data: data.filters,
                })
              ) {
                return;
              }
            } catch (e) {
              console.error(e);
            }
          }
          this._search(null, data.filters);
        }

        this.query.selectionFilters = data.filters;
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<any>('SET_CUSTOM_FIELDS').subscribe((data) => {
        this.widget.configuration['customFields'] = data.customFields;
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<any>('SET_WIDGET_CONFIG').subscribe((data) => {
        if (data.widgetId === this.widget.id) {
          this.widget.configuration = data.config;
          data.refresh && this._search();
        }
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<any>('RESIZE_WIDGET').subscribe((data) => {
        if (data && data.widgetId) {
          if (data.widgetId === this.widget.id) {
            this.resize();
          }
        } else {
          this.resize();
        }
      }),
    );
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    if (this._timer) {
      clearTimeout(this._timer);
      this._timer = null;
    }
    if (this._interval) {
      clearInterval(this._interval);
      this._interval = null;
    }
  }

  public toggleLegend(mode?: boolean): void {
    if (this.uiOption && this.chartFuncValidator.checkUseLegendByTypeString(this.chartType)) {
      const legend = _.cloneDeep(this.uiOption.legend);
      if (legend) {
        legend.auto = 'boolean' === typeof mode ? mode : !legend.auto;
        legend.convertType = LegendConvertType.SHOW;
        this.uiOption = <UIOption>_.extend({}, this.uiOption, { legend });

        this.safelyDetectChanges();
      }
    }
  }

  private applyFilters(): void {
    combineLatest({
      dashboardFilters: this.dashboardFiltersService
        .getDashboardFilters(this.dashboard.id)
        .pipe(filter((dashboardFilters) => dashboardFilters?.excludeWidgetId !== this.widget.id)),
      selectionFilters: this.filterSelectionService.getSelection(this.widget.id, this.contextId),
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ dashboardFilters, selectionFilters }) => {
        this._search(
          dashboardFilters?.filters ?? null,
          selectionFilters.map((selection) => this.filterSelectionService.mapSelectionToApiFilter(selection)),
        );
      });
  }

  //TODO move to service

  // public saveWidget() {
  //   this._dataGridComp.saveDataPreparation();
  //   this.isShowDownloadPopup = false;
  //   if (this._workbookComponent) {
  //     this._workbookComponent.gridMenuState = true;
  //   }
  // }

  public editWidgetHover() {
    if (this.isMissingDataSource) {
      this.alertPrimeService.warn(this.translateService.instant('msg.board.alert.can-not-edit-missing-datasource'));
      return;
    }
    this.broadCaster.broadcast('EDIT_WIDGET', { widgetId: this.widget.id, widgetType: this.widget.type });
  }

  public toggleMiniMap(mode?: boolean): void {
    if (this.uiOption && this.chartFuncValidator.checkUseMiniMapByTypeString(this.chartType)) {
      const chartZooms = _.cloneDeep(this.uiOption.chartZooms);
      if (chartZooms) {
        chartZooms.map((zoom) => (zoom.auto = 'boolean' === typeof mode ? mode : !zoom.auto));
        this.uiOption = <UIOption>_.extend({}, this.uiOption, { chartZooms });

        this.safelyDetectChanges();
      }
    }
  }

  public changeMode(mode: 'chart' | 'grid') {
    if (this.widget.mode !== mode) {
      this.widget.mode = mode;
      this.safelyDetectChanges();

      'grid' === mode && this._initGridChart();
      this.resize(true);
    }
  }

  public resize(isImmediate: boolean = false) {
    if (this.chart && isLegacyChartType(this.chartType)) {
      this.safelyDetectChanges();
      this._timer = setTimeout(
        () => {
          if ('barChart' in this.chart && 'lineChart' in this.chart) {
            const barChart = this.chart['barChart'] as BarChartComponent;
            const lineChart = this.chart['lineChart'] as LineChartComponent;
            barChart.chart.resize();
            lineChart.chart.resize();
          } else if (this.chart.uiOption.type === ChartType.LABEL) {
            // } else if (this.chart.uiOption.type === ChartType.GRAPH) {
            //   this.isSetChartData && (<NetworkChartComponent>this.chart).draw();
          } else {
            try {
              if (this.chart && this.chart.chart) this.chart.chart.resize();
            } catch (error) {
              console.error('Error in chart resizing', error);
            }
          }

          this.safelyDetectChanges();
        },
        isImmediate ? 0 : 300,
      );
    }

    if (this.gridChart?.pivotGrid) {
      setTimeout(() => this.gridChart.pivotGrid.arrange(), isImmediate ? 0 : 300);
    }
  }

  public isAvaliableGrid(widget: PageWidget) {
    const chartType = (<PageWidgetConfiguration>widget.configuration).chart.type.toString();
    const notAvaliableChart = ['grid', 'scatter', 'pie'];

    return notAvaliableChart.indexOf(chartType) === -1;
  }

  public chartSelectInfo(data: ChartSelectInfo) {
    if (this.layoutMode === LayoutMode.EDIT) {
      this.alertPrimeService.info(this.translateService.instant('msg.board.alert.not-select-editmode'));
    } else {
      const selectData = [];
      if (data.data) {
        data.data.map((field) => {
          let isExternalFilter = true;
          if (this.selectionFilterService.currentSelectionFilters) {
            isExternalFilter = this.selectionFilterService.currentSelectionFilters.some(
              (filter) => field.alias === filter.field,
            );
          }

          const isAlreadyFilter: boolean = this.selectionFilterService.selectFilterList.some(
            (filter) => field.alias === filter.alias,
          );

          if (!isExternalFilter || isAlreadyFilter) {
            selectData.push(field);
          }
        });
      }
      if (selectData.length == 0 && !_.eq(data.mode, ChartSelectMode.CLEAR)) {
        return;
      } else {
        data.data = selectData;
      }

      if (!data.params) {
        data.params = {
          widgetId: this.widget.id,
          externalFilters: true,
        };
      }
      const widgetDataSource: Datasource = DashboardUtil.getDataSourceFromBoardDataSource(
        this.dashboard,
        this.widgetConfiguration.dataSource,
      );
      widgetDataSource && (data.params.engineName = widgetDataSource.engineName);

      this.selectionFilterService.changeSelectFilterList(data);
      this.broadCaster.broadcast('CHART_SELECTION_FILTER', { select: data });
    }
  }

  public changeSelectFilterList(data: ChartSelectInfo): void {
    if (_.eq(data.mode, ChartSelectMode.ADD)) {
      data.data.map((field) => {
        let isAlreadyFilter = false;
        this._selectFilterList.map((filter) => {
          if (_.eq(field.alias, filter.alias)) {
            isAlreadyFilter = true;

            field.data.map((fieldData) => {
              let isAlreadyData = false;
              filter.data.map((filterData) => {
                if (_.eq(filterData, fieldData)) {
                  isAlreadyData = true;
                }
              });

              if (!isAlreadyData) {
                filter.data.push(fieldData);
              }
            });
          }
        });

        if (!isAlreadyFilter) {
          this._selectFilterList.push(_.cloneDeep(field));
        }
      });
    } else if (_.eq(data.mode, ChartSelectMode.SUBTRACT)) {
      for (let num = this._selectFilterList.length - 1; num >= 0; num--) {
        const filter = this._selectFilterList[num];
        data.data.map((field) => {
          if (_.eq(field.alias, filter.alias)) {
            for (let num2 = field.data.length - 1; num2 >= 0; num2--) {
              const data1 = field.data[num2];
              filter.data.map((data2) => {
                if (_.eq(data1, data2)) {
                  filter.data.splice(num, 1);
                }
              });
            }

            if (filter.data.length == 0) {
              this._selectFilterList.splice(num, 1);
            }
          }
        });
      }
    } else if (_.eq(data.mode, ChartSelectMode.CLEAR)) {
      data.mode = ChartSelectMode.SUBTRACT;
      data.data = this._selectFilterList;

      this._selectFilterList = [];
    }
  }

  public changeExternalFilterList(externalFilters?: Filter[]): Filter[] {
    isNullOrUndefined(externalFilters) && (externalFilters = []);

    for (let num = this._selectFilterList.length - 1; num >= 0; num--) {
      const filter = this._selectFilterList[num];
      let isNotFilter = true;
      externalFilters.map((externalFilter) => {
        if (_.eq(externalFilter.field, filter.alias)) {
          isNotFilter = false;

          for (let num2 = filter.data.length - 1; num2 >= 0; num2--) {
            const data1 = filter.data[num2];
            let isNotData = true;
            externalFilter['valueList'].map((data2) => {
              if (_.eq(data1, data2)) {
                isNotData = false;
              }
            });
            if (isNotData) {
              filter.data.splice(num, 1);
            }
          }

          if (filter.data.length == 0) {
            this._selectFilterList.splice(num, 1);
          }
        }
      });

      if (isNotFilter) {
        this._selectFilterList.splice(num, 1);
      }
    }

    if (externalFilters) {
      externalFilters = _.cloneDeep(externalFilters);

      for (let num = externalFilters.length - 1; num >= 0; num--) {
        const filter = externalFilters[num];
        this._selectFilterList.map((field) => {
          if (_.eq(field.alias, filter.field)) {
            externalFilters.splice(num, 1);
          }
        });
      }
    }

    return externalFilters;
  }

  public updateComplete() {
    if (this._isDuringProcess) {
      this.isUpdateRedraw = LayoutMode.EDIT === this.layoutMode;

      this.processEnd();
      this._isDuringProcess = false;

      if (
        !this.isGridType() &&
        this.chartFuncValidator.checkUseSelectionByTypeString(this.chartType) &&
        this.chartFuncValidator.checkUseMultiSelectionByTypeString(this.chartType)
      ) {
        switch (this.mouseMode) {
          case 'MULTI_RECT':
            this.changeMouseSelectMode('multi', 'rect');
            break;
          case 'MULTI_POLY':
            this.changeMouseSelectMode('multi', 'polygon');
            break;
        }
      }
    }
  }

  public showNoData() {
    this.isShowNoData = true;
    this.updateComplete();
  }

  public isShowWidgetName(): boolean {
    return this.isViewMode && this.widget && this.widget.name && this.isShowTitle && !this.isShowHierarchyView;
  }

  public openUserFuncInput() {
    this.isShowEvtTriggerEditor = true;
    this.safelyDetectChanges();
    $(this._userFuncInput.nativeElement).trigger('focus');
  }

  public saveUserFunc() {
    this.userCustomFunction = $(this._userFuncInput.nativeElement).val();

    this.loadingShow();
    this.widget.configuration.customFunction = this.userCustomFunction;
    const param = { configuration: _.cloneDeep(this.widget.configuration) };
    param.configuration = convertPageWidgetSpecToServer(param.configuration);
    this.widgetService
      .updateWidget(this.widget.id, param)
      .then(() => {
        this.alertPrimeService.success(this.translateService.instant('msg.comm.alert.save.success'));
        this.closeUserFuncInput();
        this.loadingHide();
        this._search();
      })
      .catch((err) => this.commonExceptionHandler(err));
  }

  public closeUserFuncInput() {
    this.isShowEvtTriggerEditor = false;
    this.safelyDetectChanges();
  }

  public getDataSourceName(): string {
    let strName = '';
    if (this.widget) {
      const widgetConf: PageWidgetConfiguration = this.widget.configuration;
      if (ChartType.MAP === widgetConf.chart.type && widgetConf.shelf.layers) {
        strName = widgetConf.shelf.layers.reduce((acc, currVal) => {
          const dsInfo: Datasource = this.dashboard.dataSources.find((item) => item.engineName === currVal.ref);
          if (dsInfo) {
            acc = '' === acc ? acc + dsInfo.name : acc + ',' + dsInfo.name;
          }
          return acc;
        }, '');
      } else if (widgetConf.dataSource) {
        const widgetDataSource: Datasource = DashboardUtil.getDataSourceFromBoardDataSource(
          this.dashboard,
          widgetConf.dataSource,
        );
        widgetDataSource && (strName = widgetDataSource.name);
      }
    }
    return strName;
  }

  public copyWidgetIdToClipboard() {
    if (this.widget) {
      this._clipboardService.copyFromContent(this.widget.id);
    }
  }

  public existPivot(category: string): boolean {
    return (
      this.widget &&
      this.widget.configuration['pivot'] &&
      this.widget.configuration['pivot'][category] &&
      0 < this.widget.configuration['pivot'][category].length
    );
  }

  public getPivotFieldsStr(category: string): string {
    let strFields = '';
    if (this.widget && this.widget.configuration['pivot']) {
      const pivotData = this.widget.configuration['pivot'][category];
      if (pivotData) {
        strFields = pivotData.map((item) => item.name).join(',');
      }
    }
    return strFields;
  }

  public existChartFilter(): boolean {
    return this.widget && this.widget.configuration.filters && 0 < this.widget.configuration.filters.length;
  }

  public getChartFilterStr(): string {
    let strFields = '';
    if (this.widget && this.widget.configuration.filters) {
      strFields = this.widget.configuration.filters.map((item) => item.field).join(',');
    }
    return strFields;
  }

  public isGridType(): boolean {
    if (this.widget) {
      const chartConf = this.widget.configuration.chart;
      return (chartConf && ChartType.GRID === chartConf.type) || 'grid' === this.widget.mode;
    } else {
      return false;
    }
  }

  public downloadChartImage() {
    this.imageService.downloadElementImage(this.$element.find('.ddp-box-widget:visible'), 'ChartImage.jpg');
  }

  public changeMouseSelectMode(mode: string, brushType: string) {
    if (isNullOrUndefined(this.chart)) {
      return;
    }
    if (ChartMouseMode.SINGLE.toString() === mode) {
      this.mouseMode = 'SINGLE';
      this.chart.convertMouseMode(ChartMouseMode.SINGLE);
    } else if (ChartMouseMode.MULTI.toString() === mode) {
      if (BrushType.RECT.toString() === brushType) {
        this.mouseMode = 'MULTI_RECT';
        this.chart.convertMouseMode(ChartMouseMode.MULTI, BrushType.RECT);
      } else {
        this.mouseMode = 'MULTI_POLY';
        this.chart.convertMouseMode(ChartMouseMode.MULTI, BrushType.POLYGON);
      }
    }
  }

  public changeMouseZoomMode(mode: string) {
    switch (mode) {
      case ChartMouseMode.DRAGZOOMIN.toString():
        this.chart.convertMouseMode(ChartMouseMode.DRAGZOOMIN);
        break;
      case ChartMouseMode.ZOOMIN.toString():
        this.chart.convertMouseMode(ChartMouseMode.ZOOMIN);
        break;
      case ChartMouseMode.ZOOMOUT.toString():
        this.chart.convertMouseMode(ChartMouseMode.ZOOMOUT);
        break;
      case ChartMouseMode.REVERT.toString():
        this.chart.convertMouseMode(ChartMouseMode.REVERT);
        break;
    }
  }

  public setForceStyle(isDisplay: boolean, zIndex: number = 3) {
    if (this.isShowDownloadPopup) {
      return;
    }
    const $container: JQuery = $('.ddp-ui-widget');
    const $contents: JQuery = $('.ddp-ui-dash-contents');
    if (isDisplay) {
      $contents.css('z-index', zIndex);
      $container.css('overflow', '');
    } else {
      $contents.css('z-index', '');
    }
  }

  public showInfoLayer(event: MouseEvent) {
    const $target: JQuery = $(event.target);
    const btnLeft: number = $target.offset().left;
    const btnTop: number = $target.offset().top;
    this.$element.find('.ddp-box-btn2 .ddp-box-layout4').css({ left: btnLeft - 150, top: btnTop + 25 });
  }

  public showPreviewDownData(event: MouseEvent) {
    this.isShowDownloadPopup = true;
    setTimeout(() => {
      this.drawDataGrid();
    }, 500);
  }

  public showDownloadLayer(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (ConnectionType.LINK === ConnectionType[this.widget.configuration.dataSource.connType]) {
      this._dataDownComp.openGridDown(event, this._dataGridComp);
    } else {
      this._dataDownComp.openWidgetDown(event, this.widget.id, this.isOriginDown, this.query);
    }
  }

  public drawDataGrid(isOriginal: boolean = false) {
    this.isOriginDown = isOriginal;
    this.isCanNotDownAggr = false;

    let fields = [];
    const clonePivot: Pivot = _.cloneDeep(this.widgetConfiguration.pivot);
    clonePivot.rows && (fields = fields.concat(clonePivot.rows));
    clonePivot.columns && (fields = fields.concat(clonePivot.columns));
    clonePivot.aggregations && (fields = fields.concat(clonePivot.aggregations));

    if (isOriginal && fields.some((field: Field) => field['field'] && field['field'].aggregated)) {
      this.isCanNotDownAggr = true;
      this.safelyDetectChanges();
      return false;
    }

    this.loadingShow();
    const param = getDownloadFilters(this.query);

    const renderGrid = (result) => {
      const headers: header[] = fields.map((field: Field) => {
        const logicalType: string =
          field['field'] && field['field'].logicalType ? field['field'].logicalType.toString() : '';
        let headerName: string = field.name;
        if (field['aggregationType']) {
          if (!isOriginal) {
            headerName = field.alias ? field.alias : field['aggregationType'] + '(' + field.name + ')';
          }
        } else if (field.alias) {
          headerName = field.alias;
        }

        return new SlickGridHeader()
          .Id(headerName)
          .Name(
            '<span style="padding-left:20px;">' +
              '<selfai-bi-shared-field-icon [view]="\'FIELD\'" [type]="\'' +
              logicalType +
              '\'"></selfai-bi-shared-field-icon>' +
              headerName +
              '</span>',
          )
          .Field(headerName)
          .Behavior('select')
          .Selectable(false)
          .CssClass('cell-selection')
          .Width(10 * headerName.length + 20)
          .MinWidth(100)
          .CannotTriggerInsert(true)
          .Resizable(true)
          .Unselectable(true)
          .Sortable(false)
          .build();
      });

      let rows: any[] = result;

      if (rows && 0 < headers.length) {
        if (rows.length > 0 && !('id' in rows[0])) {
          rows = rows.map((row: any, idx: number) => {
            Object.keys(row).forEach((key) => {
              row[key.substr(key.indexOf('.') + 1, key.length)] = row[key];
            });
            row.id = idx;
            return row;
          });
        }

        this.changeDetect.detectChanges();

        this._dataGridComp.create(headers, rows, new GridOption().SyncColumnCellResize(true).RowHeight(32).build());

        this._dataGridComp.search(this.srchText);

        this.loadingHide();
      }
    };

    if (ConnectionType.LINK === ConnectionType[this.widget.configuration.dataSource.connType]) {
      const boardConf: BoardConfiguration = this.dashboard.configuration;
      const query: SearchQueryRequest = this.datasourceService.makeQuery(
        this.widgetConfiguration,
        this.widgetConfiguration.fields,
        {
          url: this.router.url,
          dashboardId: this.dashboard.id,
          widgetId: this.widget.id,
        },
        null,
        true,
      );
      this.widgetService
        .previewConfig(query, isOriginal, false)
        .then((result) => renderGrid(result))
        .catch((err) => {
          console.error(err);
          this.loadingHide();

          this.safelyDetectChanges();
        });
    } else {
      this.widgetService
        .previewWidget(this.widget.id, isOriginal, false, param)
        .then((result) => renderGrid(result))
        .catch((err) => {
          console.error(err);
          this.loadingHide();

          this.safelyDetectChanges();
        });
    }
  }

  public setSearchText(srchText: string) {
    this.srchText = srchText;
    this._dataGridComp.search(this.srchText);
  }

  public changeDraw() {
    this._search(null, this.selectionFilterService.currentSelectionFilters);
  }

  public toggleSync() {
    this.isRealTimeWidget = !this.isRealTimeWidget;
    this.widget.configuration.sync = this.isRealTimeWidget;
    this._setSync();
  }

  private _setWidget(widget: PageWidget) {
    this.widget = <PageWidget>_.extend(
      createPageWidget({
        dashBoardId: this.dashboard.id,
      }),
      widget,
    );
    this.widgetConfiguration = <PageWidgetConfiguration>this.widget.configuration;
    this.chartType = this.widgetConfiguration.chart.type.toString();
    this.parentWidget = null;
    if (this.dashboard.configuration) {
      if (this.widgetConfiguration.customFunction && '' !== this.widgetConfiguration.customFunction) {
        this.userCustomFunction = this.widgetConfiguration.customFunction;
      }

      if (ChartType.MAP === (<PageWidgetConfiguration>this.widget.configuration).chart.type) {
        if ('default' === this.widgetConfiguration.dataSource.type) {
          const widgetDataSource: Datasource = DashboardUtil.getDataSourceFromBoardDataSource(
            this.dashboard,
            this.widgetConfiguration.dataSource,
          );
          const fields: Field[] = DashboardUtil.getFieldsForMainDataSource(
            this.dashboard.configuration,
            widgetDataSource.engineName,
          );
          fields.forEach((field) => {
            if (
              undefined !== this.widgetConfiguration.chart['layerNum'] &&
              this.widgetConfiguration.chart['layerNum'] >= 0
            ) {
              const shelfLayers: any =
                this.widgetConfiguration.shelf.layers[this.widgetConfiguration.chart['layerNum']];

              if (_.isUndefined(shelfLayers['fields'])) {
                const tempShelf: Shelf = createShelf();
                for (let idx = 0; idx < this.widgetConfiguration.shelf.layers.length; idx++) {
                  const tempLayer: any = _.cloneDeep(this.widgetConfiguration.shelf.layers[idx]);
                  if (_.isUndefined(tempShelf.layers[idx])) {
                    const shelfLayers: ShelfLayers = createShelfLayers();
                    tempShelf.layers.push(shelfLayers);
                  }
                  tempShelf.layers[idx].fields = tempLayer;
                }
                this.widgetConfiguration.shelf = tempShelf;
              }

              this.widgetConfiguration.shelf.layers[this.widgetConfiguration.chart['layerNum']].fields.forEach(
                (abstractField) => {
                  if (
                    isNullOrUndefined(abstractField.field) &&
                    String(field.type) == abstractField.type.toUpperCase() &&
                    field.name == abstractField.name
                  ) {
                    abstractField.field = field;
                  }
                },
              );
            }
          });
        }

        this._setCommonConfig(this.widget);
      } else {
        const widgetDataSource: Datasource = DashboardUtil.getDataSourceFromBoardDataSource(
          this.dashboard,
          this.widgetConfiguration.dataSource,
        );

        if (isNullOrUndefined(widgetDataSource)) {
          this.processStart();
          this._isDuringProcess = true;
          this.isMissingDataSource = true;
          this._showError({
            code: 'GB0000',
            details: this.translateService.instant('msg.board.error.missing-datasource'),
          });
          this.updateComplete();
        } else {
          this.isMissingDataSource = false;

          const fields: Field[] = DashboardUtil.getFieldsForMainDataSource(
            this.dashboard.configuration,
            widgetDataSource.engineName,
          );
          fields.forEach((field) => {
            this.widgetConfiguration.pivot.rows.forEach((abstractField) => {
              if (
                isNullOrUndefined(abstractField.field) &&
                String(field.type) == abstractField.type.toUpperCase() &&
                field.name == abstractField.name
              ) {
                abstractField.field = field;
              }
            });

            this.widgetConfiguration.pivot.columns.forEach((abstractField) => {
              if (
                isNullOrUndefined(abstractField.field) &&
                String(field.type) == abstractField.type.toUpperCase() &&
                field.name == abstractField.name
              ) {
                abstractField.field = field;
              }
            });

            this.widgetConfiguration.pivot.aggregations.forEach((abstractField) => {
              if (
                isNullOrUndefined(abstractField.field) &&
                String(field.type) == abstractField.type.toUpperCase() &&
                field.name == abstractField.name
              ) {
                abstractField.field = field;
              }
            });
          });

          this._setCommonConfig(this.widget);
        }
      }
    }

    this.safelyDetectChanges();
    this.isInvalidPivot = !this.chart.isValid(
      this.widgetConfiguration.pivot as any,
      this.widgetConfiguration.shelf as any,
    );
  }

  private _setCommonConfig(widget: PageWidget) {
    this.isMissingDataSource = false;

    const boardConf: BoardConfiguration = this.dashboard.configuration;

    if (boardConf.relations) {
      const relations: DashboardPageRelation[] = boardConf.relations;
      const parentWidgetId: string = findParentWidgetId(widget.id, relations);
      if (parentWidgetId) {
        this.parentWidget = this.dashboard.widgets.find((item) => item.id === parentWidgetId);
        this.isShowHierarchyView = true;
      }

      this._childWidgetIds = findChildWidgetIds(widget.id, relations);
    }

    this.isRealTimeDs = boardConf.options.sync && boardConf.options.sync.enabled;
    this.isRealTimeWidget = this.isRealTimeDs && false !== widget.configuration.sync;
    this._setSync();

    this.safelyDetectChanges();

    if (this.parentWidget) {
      this.processStart();
      this._isDuringProcess = true;
      this.updateComplete();
    } else {
      this.applyFilters();
    }
  }

  private _setSync() {
    const boardConf: BoardConfiguration = this.dashboard.configuration;
    if (this.layoutMode !== LayoutMode.EDIT && this.isRealTimeWidget) {
      const syncOpts: BoardSyncOptions = boardConf.options.sync;
      this._interval = setInterval(() => {
        this.safelyDetectChanges();
        if (this.parentWidget) {
          this.processStart();
          this._isDuringProcess = true;
          this.updateComplete();
        } else {
          this._search();
        }
      }, syncOpts.interval * 1000);
    } else {
      if (this._interval) {
        clearInterval(this._interval);
        this._interval = undefined;
      }
    }
  }

  private async _search(globalFilters?: Filter[], selectionFilters?: Filter[]) {
    if (
      selectionFilters &&
      selectionFilters.some((item) => this._childWidgetIds.indexOf(item['selectedWidgetId']) > -1)
    ) {
      return;
    }

    this._isDuringProcess = true;
    this.isSetChartData = false;

    if (!this.chart) {
      this.updateComplete();
      return;
    }

    if (!this.uiOption.version || this.uiOption.version < SPEC_VERSION) {
      this.uiOption = OptionGenerator.initUiOption(this.uiOption);
    }

    const currentSelectionFilters: Filter[] = this.selectionFilterService.changeExternalFilterList(selectionFilters);

    if (this.parentWidget) {
      if (currentSelectionFilters) {
        const idx = currentSelectionFilters.findIndex((item) => this.parentWidget.id === item['selectedWidgetId']);
        if (-1 < idx) {
          this.isShowHierarchyView = false;
        } else {
          this.isShowHierarchyView = true;
          this.updateComplete();
          this.safelyDetectChanges();
          return;
        }
      } else {
        this.isShowHierarchyView = true;
        this.updateComplete();
        this.safelyDetectChanges();
        return;
      }
    }

    const boardConf: BoardConfiguration = this.dashboard.configuration;

    const boardCustomFields: CustomField[] = boardConf.customFields;
    if (boardCustomFields && 0 < boardCustomFields.length) {
      const chartCustomField: CustomField[] = this.widgetConfiguration.customFields;
      if (!chartCustomField || chartCustomField.length !== boardCustomFields.length) {
        this.widgetConfiguration.customFields = $.extend(chartCustomField, _.cloneDeep(boardCustomFields));
      }
    }

    if (ChartType.MAP === this.widgetConfiguration.chart.type) {
      let targetDs: Datasource;
      if ('multi' === boardConf.dataSource.type) {
        const targetBoardDs: BoardDataSource = boardConf.dataSource.dataSources.find((item) => {
          return item.engineName === this.widgetConfiguration.shelf.layers[0].ref;
        });
        targetDs = DashboardUtil.getDataSourceFromBoardDataSource(this.dashboard, targetBoardDs);
      } else {
        targetDs = DashboardUtil.getDataSourceFromBoardDataSource(this.dashboard, boardConf.dataSource);
      }

      if (isNullOrUndefined(this.widgetConfiguration.chart['lowerCorner']) && targetDs.summary) {
        this.widgetConfiguration.chart['lowerCorner'] = targetDs.summary['geoLowerCorner'];
        this.widgetConfiguration.chart['upperCorner'] = targetDs.summary['geoUpperCorner'];
      }
    }

    const query: SearchQueryRequest = this.datasourceService.makeQuery(
      this.widgetConfiguration,
      this.widgetConfiguration.fields,
      {
        url: this.router.url,
        dashboardId: this.dashboard.id,
        widgetId: this.widget.id,
      },
      null,
      true,
    );

    if (
      ChartType.MAP !== this.widgetConfiguration.chart.type &&
      query.pivot.columns.length + query.pivot.rows.length + query.pivot.aggregations.length === 0
    ) {
      this.updateComplete();
      return;
    }

    const uiCloneQuery = _.cloneDeep(query);

    let calculatedFilters: Filter[] = [];

    try {
      calculatedFilters = await this.widgetFilterService.calculateSearchFilters(
        this.dashboard,
        this.widget,
        globalFilters,
        currentSelectionFilters,
      );

      this.injector.get(GlobalActiveFiltersService).setFilters(this.dashboard.id, calculatedFilters, this.contextId);
    } catch (err) {
      let details = err;
      if (err === 'GB0000') {
        details = this.translateService.instant('msg.board.error.missing-datasource');
      }
      this.isMissingDataSource = true;
      this._showError({
        code: err,
        details,
      });
      this.updateComplete();
    }

    uiCloneQuery.filters = [...uiCloneQuery.filters, ...calculatedFilters];

    this.isShowNoData = false;
    this._hideError();

    const cloneQuery = makeSearchQueryParam(_.cloneDeep(uiCloneQuery));

    this.query = cloneQuery;
    if (this.chartType === 'label') {
      this.chart['setQuery'] = this.query;
    }

    const cloneGlobalFilters = _.cloneDeep(globalFilters);
    if (cloneGlobalFilters) {
      cloneGlobalFilters.forEach((gf) => {
        delete gf['clzField'];
        delete gf['fieldObj'];
      });
    }

    const isClear: boolean =
      this.chart &&
      'function' === typeof this.chart.clear &&
      (this.selectionFilterService.currentSelectionFilterString !== JSON.stringify(currentSelectionFilters) ||
        this._currentGlobalFilterString !== JSON.stringify(cloneGlobalFilters));

    this.selectionFilterService.currentSelectionFilters = currentSelectionFilters;
    this.selectionFilterService.currentSelectionFilterString = JSON.stringify(currentSelectionFilters);
    this._currentGlobalFilterString = JSON.stringify(cloneGlobalFilters);

    this.datasourceService
      .searchQuery(cloneQuery, this.isRealTimeWidget)
      .then((data) => {
        if (
          this.resultData &&
          this.isRealTimeWidget &&
          cloneQuery.pivot.columns.some((item) => 'TIMESTAMP' === item.subRole)
        ) {
          const columnSize = data.columns.length;
          const newData = data.rows
            .map((rowItem, rowIndex) => {
              const columnValues = [];
              for (let columnIdx = 0; columnIdx < columnSize; ++columnIdx) {
                const columnValue = data.columns[columnIdx].value;
                columnValues.push(columnValue[rowIndex]);
              }
              return {
                row: rowItem,
                col: columnValues,
              };
            })
            .sort((a, b) => {
              return moment(a.row).isBefore(moment(b.row));
            });
          newData.forEach((newItem) => {
            if (!this.resultData.data.rows.some((row) => row === newItem.row)) {
              this.resultData.data.rows.push(newItem.row);
              for (let columnIdx = 0; columnIdx < columnSize; ++columnIdx) {
                this.resultData.data.columns[columnIdx].value.push(newItem.col[columnIdx]);
              }
            }
          });
        } else {
          // Remove count field in grid chart, because it is not visible
          // TODO Need fix it later
          if (
            this.widgetConfiguration?.chart.type == 'grid' &&
            this.uiOption.dataType === 'MASTER' &&
            data.columns &&
            data.columns.length > 0
          ) {
            const countIndex = data.columns.findIndex((item) => item.name === 'COUNT(count)');
            if (countIndex > -1) {
              data.columns.splice(countIndex, 1);
            }
          }

          this.resultData = {
            data,
            config: query,
            uiOption: this.uiOption,
            params: {
              widgetId: this.widget.id,
              externalFilters: currentSelectionFilters !== undefined && 0 < currentSelectionFilters.length,
              selectFilterListList: this.selectionFilterService.selectFilterList,
            },
          };
        }

        const optionKeys = Object.keys(this.uiOption);
        if (optionKeys && optionKeys.length === 1) {
          delete this.resultData.uiOption;
        }

        this._initGridChart();

        if (this.layoutMode === LayoutMode.EDIT && this.resultData.params) {
          delete this.resultData.params;
        }

        setTimeout(() => {
          isClear && this.chart.clear();

          if (this.chartType === 'line' && this.resultData.data.columns && this.resultData.data.columns.length > 0) {
            this.getAnalysis(cloneQuery);
          } else {
            this.chart.resultData = this.resultData;
            this.isSetChartData = true;
          }

          this.limitInfo = DashboardUtil.getChartLimitInfo(
            this.widget.id,
            ChartType[this.chartType.toUpperCase()],
            data,
          );
          if (this.layoutMode === LayoutMode.EDIT) {
            this.broadCaster.broadcast('WIDGET_LIMIT_INFO', this.limitInfo);
          }
        }, 1000);

        this.safelyDetectChanges();
      })
      .catch((error) => {
        this._showError(error);
        this.updateComplete();

        this.safelyDetectChanges();
      });
  }

  private _initGridChart() {
    try {
      if (this.gridChart && this.gridChart.isLoaded) {
        this.gridChart.resultData = this.resultData;
      }
    } catch (err) {
      console.error(err);
    }
  }

  private isAnalysisPredictionEnabled(): boolean {
    return !_.isUndefined(this.widgetConfiguration.analysis) && !_.isEmpty(this.widgetConfiguration.analysis);
  }

  private predictionLineDisabled(): void {
    this.chart.analysis = null;
    this.chart.resultData = this.resultData;
  }

  private getAnalysis(query: SearchQueryRequest): void {
    if (this.isAnalysisPredictionEnabled()) {
      Promise.resolve().then(() => {
        if (this.isAnalysisPredictionEnabled()) {
          this.analysisPredictionService
            .getAnalysisPredictionLineFromDashBoard(
              this.widgetConfiguration,
              this.chart,
              this.resultData,
              query.filters,
            )
            .catch((error) => {
              this._showError(error);
              this.updateComplete();
            });
        } else {
          this.predictionLineDisabled();
        }
      });
    } else {
      this.predictionLineDisabled();
    }
  }

  private _checkDatasource(): void {
    let valid = true;
    let invalidDatasourceName = '';
    if (
      this.widget.configuration.chart.type === ChartType.MAP &&
      !_.isNil(this.widget.configuration.dataSource.dataSources)
    ) {
      for (const widgetDatasource of this.widget.configuration.dataSource.dataSources) {
        for (const dashboardDatasource of this.dashboard.dataSources) {
          if (widgetDatasource.id == dashboardDatasource.id) {
            if (!dashboardDatasource.valid) {
              valid = false;
              if (invalidDatasourceName !== '') {
                invalidDatasourceName += ', ';
              }
              invalidDatasourceName += dashboardDatasource.name;
            }
          }
        }
      }
    } else {
      for (const dashboardDatasource of this.dashboard.dataSources) {
        if (this.widget.configuration.dataSource.id == dashboardDatasource.id) {
          if (!dashboardDatasource.valid) {
            valid = false;
            invalidDatasourceName = dashboardDatasource.name;
          }
        }
      }
    }

    if (!valid) {
      this._showError({
        code: 'GB0000',
        details: this.translateService.instant('msg.board.error.deny-datasource', {
          datasource: invalidDatasourceName,
        }),
      });
    }
  }
}
